import React from "react";
import { motion } from "framer-motion";
import "./education.css";
import Menu from "../../Components/Menu/Menu";

const educationData = [
  {
    institution: "University of XYZ",
    degree: "Bachelor of Science in Accounting and Finance",
    year: "2019 - 2023",
    description:
      "Focused on financial analysis, accounting principles, and business management.",
  },
  {
    institution: "ABC High School",
    degree: "High School Diploma",
    year: "2015 - 2019",
    description:
      "Graduated with honors, specializing in mathematics and economics.",
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Education = () => {
  return (
    <div className="education-header">
      <motion.div
        className="education-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h1 className="section-title">Education</h1>
        <div className="education-list">
          {educationData.map((item, index) => (
            <motion.div
              key={index}
              className="education-item"
              variants={itemVariants}
            >
              <h2 className="institution">{item.institution}</h2>
              <p className="degree">{item.degree}</p>
              <p className="year">{item.year}</p>
              <p className="description">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Education;
