import React from "react";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import Menu from "./Components/Menu/Menu";

const Layout = () => {
  return (
    <div className="layout-container">
      <Menu />
      <div className="content-container">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
