import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import SplashScreen from "./Screens/SplashScreen";
import Home from "./Screens/Home/Home";
import Education from "./Screens/Education/education";
import Layout from "./Layout";

function App() {
  return (
    <Router>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/home" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="education" element={<Education />} />
            {/* Add other routes like Experience and About here */}
          </Route>
        </Routes>
      </AnimatePresence>
    </Router>
  );
}

export default App;
