import React from "react";
import { motion } from "framer-motion";
import "./Home.css";
import Menu from "../../Components/Menu/Menu";

const texts = [
  "Hi,",
  "My name is Shiza Batool,",
  "I'm a Student of Accounting and Finance",
];

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
  exit: { opacity: 0, y: -20, transition: { duration: 1, ease: "easeInOut" } },
};

const Home = () => {
  const [currentTextIndex, setCurrentTextIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="shome-container">
      <motion.div
        key={currentTextIndex}
        variants={textVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="text-container"
      >
        {texts[currentTextIndex]}
      </motion.div>
    </div>
  );
};

export default Home;
